<template>
  <div class="container-fluid">
    <CRow class="p-3">
      <h4 class="text-uppercase">Reports</h4>
    </CRow>   
    <CCard>
      <CRow class="p-3 m-4 d-flex justify-content-center">
        <CCol cols="12" v-if="checkUsrPermissions(['Reports_all', 'Reports_billing'])">
          <div
            class="mr-4 mb-5 box text-center"
            @click="goToLinks('Billing')"
          >
            <h4>Billing</h4>
          </div>
        </CCol>
        <CCol cols="12" md="3" v-if="checkUsrPermissions(['Reports_all', 'ReportsLed_billing'])">
          <div
            class="mr-4 mb-5 box text-center"
            @click="goToLinks('Ledger')"
          >
            <h4>Ledger Billing</h4>
          </div>
        </CCol>       
        <CCol cols="12" md="3" v-if="checkUsrPermissions(['Reports_all', 'ReportsArr_billing'])">
          <div
            class="mr-4 mb-5 box text-center"
            @click="goToLinks('Arrears')"
          >
            <h4>Arrears Billing</h4>
          </div>
        </CCol>

        <CCol cols="12" md="3" v-if="checkUsrPermissions(['Reports_all', 'Reports_analytics'])">
          <div class="mr-4 mb-5 box text-center" @click="goToLinks('Analytics')">
            <h4>Analytics</h4>
          </div>
        </CCol>

        <CCol cols="12" md="3" v-if="checkUsrPermissions(['Reports_all', 'Reports_growth'])">
          <div class="mr-4 mb-5 box text-center" @click="goToLinks('GrowthReport')">
            <h4>Growth Report</h4>
          </div>
        </CCol>
        
        <CCol cols="12" md="3" v-if="checkUsrPermissions(['Reports_all', 'Reports_ward'])">
          <div class="mr-4 mb-5 box text-center" @click="goToLinks('WardReport')">
            <h4>Ward-wise Report</h4>
          </div>
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
export default {  
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"", isActive: true}] 
    }
  },
  methods: {
    goToLinks(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style  scoped>
.card {
  border: none;
  padding: 80px 70px;
}
.box {
  -webkit-box-shadow: 5px 5px 15px -2px #0000008c;
  box-shadow: 5px 5px 15px -2px #0000008c;
  padding: 30px;
  cursor: pointer;
  position: relative;
  width: auto;
  height: 180px;
  margin-right: 5px;
  margin-left: 5px;
}

/* .custom-margin {
  margin-right: 70px;
  margin-bottom: 50px;
} */

.box h4 {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
}
</style>


<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
</style>